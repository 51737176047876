import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, People } from '@components';
import { graphql } from 'gatsby';

const { Banner } = Banners;

const Index = ({ data }) => (
  <Layout
    bannerChildren={
      <Banner
        title='People'
        description="Find out who the people helping fulfill TAIK's mission and vision are. These are Africans on the continent and in the diaspora who strongly believe in changing things for the better."
      />
    }
  >
    <SEO
      title='PEOPLE'
      description="Find out who the people helping fulfill TAIK's mission and vision are. These are Africans on the continent and in the diaspora who strongly believe in changing things for the better."
    />
    <People data={data} />
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "volunteer" } } }
      sort: { fields: frontmatter___name, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            name
            country
            role
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            team
          }
          id
        }
      }
    }
  }
`;

export default Index;
